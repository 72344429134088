import React, { useEffect, useState } from 'react'
import { Field, Form, Formik } from 'formik';
import {
    Grid,
    TextField,
    Box,
    Typography,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    OutlinedInput,
    FormHelperText,
    Stack,
    Button,
  } from '@mui/material';
import CustomButton from '../../components/@extended/CustomButton';
import Phone, {phoneValidationSchema} from '../../components/common/Phone';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAddSponsorsMutation } from '../../store/slices/apiSlice';
import { openMessage } from '../../store/slices/showMessageSlice';
import GooglePlacesAutocompleteComponent from '../../components/common/GooglePlacessAutocomplete';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    orgnizationName: Yup.string().required('Organization Name is required'),
    orgnizationCategory: Yup.string().required('Organization Category is required'),
    contactNumber: phoneValidationSchema.concat(Yup.string().required('Contact Number is required')),
    contactPerson: Yup.string().required('Contact Person is required'),
    contactEmail: Yup.string().email('Invalid email address').required('Contact Email is required'),
    fax: phoneValidationSchema
  });

export default function SponserDetails({setSponsorId,handleTabChange,onClose ,address ,setAddress ,addressError ,setAddressError ,addressDetails ,setAddressDetails ,handleClose}) {
    const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addSponsor] = useAddSponsorsMutation();
  const [orgCategoryOtherText,setOrgCategoryOtherText]=useState('');
  const[toggleForOtherInOrgCategory,settoggleForOtherInOrgCategory]=useState(false);
  const [orgCategory, setOrgCategory] = useState([
    {
      label: "Pharmaceutical",
      value: "Pharmaceutical",
    },
    {
      label: "Biotechnology",
      value: "Biotechnology",
    },
    {
      label: "Academic",
      value: "Academic",
    },
    {
      label: "Non-Profit",
      value: "Non-Profit",
    },
    {
      label: "Government",
      value: "Government",
    },
    {
      label: "Contract Research Organization (CRO)",
      value: "Contract Research Organization (CRO)",
    },
    {
      label: "Medical Device",
      value: "Medical Device",
    },
    {
      label: "Healthcare Provider",
      value: "Healthcare Provider",
    },
    {
      label: "Consulting",
      value: "Consulting",
    }
  ]);


  const openMessageNotification = (message) => {
    dispatch(openMessage({ message: message.message, messageSeverity: message.type }));
  };


  const handleSubmitOtherOfRoutesOfAdministation = ()=>{
    let other = []
    if (orgCategoryOtherText){
      for (let i of orgCategoryOtherText?.split(' ')) {
        if (i !== '') other.push(i);
      }
        other = other.join(" ")

        if(other!==''){
          const isExisting = orgCategory.some(category => category.label === other || category.value === other);
          if(isExisting){
            openMessageNotification({
              message: 'Organization Category already exists',
              type: 'error',
            });
          }else{
            let clonedRoutesOfAdministation=[...orgCategory];

            clonedRoutesOfAdministation.push({
              label:other,
              value:other
            })
            setOrgCategory(clonedRoutesOfAdministation);
            settoggleForOtherInOrgCategory(false);
          }
        }
        setOrgCategoryOtherText('')
    }
  }

  useEffect(() => {
    if (address) {
      setAddressError('');
      geocodeByAddress(address?.label)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) =>
          setAddressDetails({ latitude: lat, longitude: lng, address: address.label })
        );
    } else {
      setAddressDetails();
    }
  }, [address]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    values = {
      orgnizationName: values.orgnizationName.trim(),
      orgnizationCategory: values.orgnizationCategory,
      contactNumber: values.contactNumber,
      contactPerson: values.contactPerson,
      contactEmail: values.contactEmail,
      fax: values.fax,
    };
    if (!values.fax) {
      delete values.fax;
    }
    if (!address) {
      setAddressError('Address is required');
      setSubmitting(false);
      return;
    }
    try {
      const result = await addSponsor({ payload: { ...values, ...addressDetails } });
      if (result.error) {
        let errorMessage = 'An error occurred';
        if (result.error.status === 401) {
          errorMessage = 'Unauthorized access';
        } else if (result.error.data.message.includes('orgnization_name')) {
          errorMessage = 'Organization name already exists';
        } else if (result.error.data.message.includes('contact_number')) {
          errorMessage = 'Contact number already exists';
        }else if (result.error.data.message.includes('contact_email')) {
          errorMessage = 'Contact email already exists';
        }else if (result.error.data.message.includes('fax')) {
          errorMessage = 'Fax already exists';
        }else if (result.error.data.message) {
          errorMessage = result.error.data.message;
        }
        
        openMessageNotification({
          message: errorMessage,
          type: 'error',
        });
      } else {
        // navigate('/sponsor');
        openMessageNotification({
          message: 'Sponsor Created Successfully',
          type: 'success',
        });
        resetForm();
        setAddress(null);
        setSponsorId(result.data.id)
        handleTabChange(null ,1)
      }
    } catch (error) {
      openMessageNotification({
        message: 'An unexpected error occurred',
        type: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };


  return (
    <Formik
      initialValues={{
        orgnizationName: '',
        orgnizationCategory: '',
        contactNumber: '',
        contactPerson: '',
        contactEmail: '',
        fax: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, touched, errors, handleChange, values, resetForm }) => (
        
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} pb={2}>
                  <TextField
                    label="Organization Name"
                    fullWidth
                    name="orgnizationName"
                    value={values.orgnizationName}
                    onChange={handleChange}
                    size="small"
                    error={touched.orgnizationName && Boolean(errors.orgnizationName)}
                    helperText={touched.orgnizationName && errors.orgnizationName}
                  />
                </Grid>
                <Grid item xs={12} md={6} pb={2}>
                <FormControl fullWidth error={touched.orgnizationCategory && Boolean(errors.orgnizationCategory)} size="small">
                    <InputLabel id="orgnizationCategory-label">Organization Category</InputLabel>
                    <Select
                        labelId="orgnizationCategory-label"
                        id="orgnizationCategory"
                        name="orgnizationCategory"
                        value={values.orgnizationCategory}
                        onChange={handleChange}
                        label="Organization Category"
                    >
                        <MenuItem disabled value="">
                          <em style={{ color: '#aaa', fontStyle: 'normal' }}>Organization Category</em>
                        </MenuItem>
                        {orgCategory.map((option) => {
                          return (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          );
                        })}
                        {toggleForOtherInOrgCategory ? (
                        <Stack direction="row" spacing={1} sx={{ pl: 2, pr: 2, pt: 1 }}>
                          <TextField
                            size="small"
                            fullWidth
                            placeholder="Other"
                            value={orgCategoryOtherText}
                            onChange={(e) => {
                              e.stopPropagation();
                              setOrgCategoryOtherText(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              e.stopPropagation();
                            }}
                          />
                          <CustomButton
                            sx={{ height: 39 }}
                            variant="contained"
                            onClick={handleSubmitOtherOfRoutesOfAdministation}
                          >
                            submit
                          </CustomButton>
                        </Stack>
                      ) : (
                        <Button
                          fullWidth
                          disableRipple
                          sx={{
                            ':hover': {
                              backgroundColor: 'transparent',
                            },
                            justifyContent: 'flex-start',
                            pl: 2,
                            color: 'black',
                            fontSize: '16px',
                          }}
                          onClickCapture={(e) => {
                            e.stopPropagation();
                            settoggleForOtherInOrgCategory(true);
                          }}
                        >
                          Other
                        </Button>
                      )}
                    </Select>
                    <FormHelperText>{touched.orgnizationCategory && errors.orgnizationCategory}</FormHelperText>
                </FormControl>
                </Grid>
                <Grid item xs={12} md={6} pb={2}>
                  <Field name="contactNumber" component={Phone} label="Contact Number" />
                </Grid>
                <Grid item xs={12} md={6} pb={2}>
                  <Field name="fax" component={Phone} label="Fax" />
                </Grid>
                <Grid item xs={12} md={6} pb={2}>
                  <TextField
                    fullWidth
                    label="Contact Person"
                    name="contactPerson"
                    value={values.contactPerson}
                    onChange={handleChange}
                    size="small"
                    error={touched.contactPerson && Boolean(errors.contactPerson)}
                    helperText={touched.contactPerson && errors.contactPerson}
                  />
                </Grid>
                <Grid item xs={12} md={6} pb={2}>
                  <TextField
                    fullWidth
                    type="email"
                    name="contactEmail"
                    value={values.contactEmail}
                    onChange={handleChange}
                    label="Email"
                    size="small"
                    error={touched.contactEmail && Boolean(errors.contactEmail)}
                    helperText={touched.contactEmail && errors.contactEmail}
                  />
                </Grid>
                <Grid item xs={12} md={12} >
                  <GooglePlacesAutocompleteComponent address={address} setAddress={setAddress}/>
                  {addressError && (
                    <Typography sx={{ fontSize: '12px', color: '#C62828', mt: 1 }}>{addressError}</Typography>
                  )}
                  {/* <Tooltip title={"Select Location"}>
                    <PinDropRoundedIcon sx={{ fontSize: '30px', color: '#888' }} />
                  </Tooltip> */}
                </Grid>
                <Grid item xs={12} display={'flex'} justifyContent={'center'} mb={0}>
                  <Box paddingRight={1.5}>
                    <CustomButton variant="outlined" onClick={() => handleClose()}>
                      Cancel
                    </CustomButton>
                  </Box>
                  <Box>
                    <CustomButton type="submit" variant="contained" disabled={isSubmitting}>
                      Submit
                    </CustomButton>
                  </Box>
                </Grid>
              </Grid>
            </Form>
      )}
    </Formik>
  )
}

// material-ui
import { Box, IconButton, Typography, useTheme } from "@mui/material";

// project import
import NavGroup from "./NavGroup";
import menuItem from "../../../menu-item";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Logo from "../../../../../components/Logo/Logo";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import GroupsIcon from '@mui/icons-material/Groups';
import _ from "lodash";
import { useGetLibraryFormQuery, useGetSubjectDetailQuery, useGetTrialSiteInfoQuery } from "../../../../../store/slices/apiSlice";
import { useResponsive } from "../../../../../hooks/ResponsiveProvider";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SettingsIcon from '@mui/icons-material/Settings';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { subjectDashboardDetails } from "../../../../../store/slices/subjectSlice";
import { selectCurrentUser } from "../../../../../store/slices/authSlice";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { getUserRole } from "../../../../../pages/util";
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import { getLeftNavCategoryIcon } from "../../../../../util/util";
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import SpaIcon from '@mui/icons-material/Spa';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = ({ open, handleDrawerToggle }) => {
  const subjectDashboardInfo = useSelector(subjectDashboardDetails);
  const user = useSelector(selectCurrentUser);
  const userRole = getUserRole(user);
  const theme = useTheme();
  const { isSmallScreen } = useResponsive();
  const sponsorId = useSelector((state) => state.auth.sponsorId);
  const trialId = subjectDashboardInfo?.crfDetail.trialId;
  const siteId = subjectDashboardInfo?.crfDetail.siteId;
  const trialSiteId = subjectDashboardInfo?.siteTrialDetail.id;
  const subjectMasterId = subjectDashboardInfo?.crfDetail.subjectMasterId;
  const subjectLoggedIn = useSelector((state) => state.auth.subjectLoggedIn);
  const userDetails = useSelector((state) => state?.userDetails?.loggedInUser);
  const { data: trialSiteData } = useGetTrialSiteInfoQuery({
    sponsorId: sponsorId,
    trialId: trialId,
    siteTrialId: trialSiteId,
  }, { skip: !trialId });
  //const { data: subjectDetailWithSteps } = useGetSubjectDetailQuery(subjectMasterId)//subjectMasterId);
  const enrolmentDocumentKey = useSelector((state) => state?.trial?.trialSiteDetail?.siteTrialData?.trial?.eConsentForm);
  const { data: enrollmentFormData } = useGetLibraryFormQuery(
    `${process.env.REACT_APP_API_ENDPOINT_URL}form-library/library/bloqcube/${enrolmentDocumentKey}`, {
    skip: !enrolmentDocumentKey,
  }
  );

  const crfStepStatus = useMemo(() => {
    switch (userRole) {
      case "subject":
        const _enrollmentTempArr = [];
        if (enrollmentFormData) {
          const steps = enrollmentFormData?.steps.filter((step) => step?.eConsentAccess);
          for (let i = 0; i < steps.length; i++) {
            _enrollmentTempArr.push({
              id: steps[i].key,
              title: steps[i].lableAbrivation,
              type: 'item',
              url: `eConcent/${trialId}/trial-site/${trialSiteId}/site/${siteId}/subject/${subjectMasterId}/enrollDetails/${steps[i].key}`,
              icon: getLeftNavCategoryIcon(steps[i].key),
              breadcrumbs: false,
            })
          }
        }
        if (subjectDashboardInfo?.crfDetail) {
          const steps = _.sortBy(subjectDashboardInfo?.crfDetail?.stepStatus, 'order');
          const _tempArr = [];
          for (let i = 0; i < steps.length; i++) {
            _tempArr.push({
              id: steps[i].stepKey,
              title: steps[i].stepLabel,
              type: 'item',
              url: `/eConcent/${trialId}/trial-site/${trialSiteId}/site/${siteId}/subject/${subjectMasterId}/crf/${subjectDashboardInfo?.crfDetail.id}/${steps[i].stepKey}/subject`,
              icon: GroupsIcon,
              breadcrumbs: false,
            });
          }
          const _leftMenuItems = _.cloneDeep(menuItem.items);
          _leftMenuItems[0].children.push({
            id: 'profile',
            title: 'My Profile',
            type: 'item',
            url: '/profile',
            icon: AccountCircleIcon,
            breadcrumbs: false,
          });
          _leftMenuItems[0].children.push({
            id: 'followup',
            title: 'Book Follow-Up',
            type: 'item',
            url: '/followup',
            icon: MedicalInformationIcon,
            breadcrumbs: false,
          });
          const updatedTasks = _leftMenuItems[0].children.concat(_enrollmentTempArr).concat(_tempArr);
          _leftMenuItems[0].children = updatedTasks;
          _leftMenuItems[0].children.push({
            id: 'instructions',
            title: 'Additional Info',
            type: 'item',
            url: `eConcent/${trialId}/trial-site/${trialSiteId}/site/${siteId}/subject/${subjectMasterId}/enrollDetails/InstructionsAndGuidance`,
            icon: AccountBalanceIcon,
            breadcrumbs: false,
          });
          return _leftMenuItems;
        }
        break;
      case 'PI':
      case 'site_coordinator':
        if (!subjectLoggedIn) {
          const _leftMenuItems = _.cloneDeep(menuItem.items);
          if (userDetails && userDetails?.dashboardPreferences?.length >= 0) {
            _leftMenuItems[0].children.push({
              id: 'reportingDashboard',
              title: 'Reporting Dashboard',
              type: 'item',
              url: '/reportingDashboard',
              icon: DashboardIcon,
              breadcrumbs: false,
            });
          }
          _leftMenuItems[0].children.push({
            id: 'schedule',
            title: 'Schedule',
            type: 'item',
            url: '/schedule',
            icon: CalendarMonthIcon,
            breadcrumbs: false,
          });
          _leftMenuItems[0].children.push({
            id: 'booking',
            title: 'Booking Slot',
            type: 'item',
            url: '/booking',
            icon: InsertInvitationIcon,
            breadcrumbs: false,
          });
          _leftMenuItems[0].children.push({
            id: 'DOALogs',
            title: 'DOA Logs',
            type: 'item',
            url: '/doaLogs',
            icon: DescriptionIcon,
            breadcrumbs: false,
          });
          _leftMenuItems[0].children.push({
            id: 'profile',
            title: 'Profile',
            type: 'item',
            url: '/accountDetails',
            icon: AccountCircleIcon,
            breadcrumbs: false,
          });
          return _leftMenuItems;
        }
        break;
      case 'site_monitor':
        if (!subjectLoggedIn) {
          const _leftMenuItems = _.cloneDeep(menuItem.items);
          if (userDetails && userDetails?.dashboardPreferences?.length >= 0) {
            _leftMenuItems[0].children.push({
              id: 'reportingDashboard',
              title: 'Reporting Dashboard',
              type: 'item',
              url: '/reportingDashboard',
              icon: DashboardIcon,
              breadcrumbs: false,
            });
          }
          _leftMenuItems[0].children.push({
            id: 'DOALogs',
            title: 'DOA Logs',
            type: 'item',
            url: '/doaLogs',
            icon: DescriptionIcon,
            breadcrumbs: false,
          });
          _leftMenuItems[0].children.push({
            id: 'schedule',
            title: 'Schedule',
            type: 'item',
            url: '/schedule',
            icon: CalendarMonthIcon,
            breadcrumbs: false,
          });
          _leftMenuItems[0].children.push({
            id: 'booking',
            title: 'Booking Slot',
            type: 'item',
            url: '/booking',
            icon: InsertInvitationIcon,
            breadcrumbs: false,
          });
          _leftMenuItems[0].children.push({
            id: 'profile',
            title: 'Profile',
            type: 'item',
            url: '/accountDetails',
            icon: AccountCircleIcon,
            breadcrumbs: false,
          });
          return _leftMenuItems;
        }
        break;
      case 'site_admin':
        if (!subjectLoggedIn) {
          const _leftMenuItems = _.cloneDeep(menuItem.items);
          if (userDetails && userDetails?.dashboardPreferences?.length >= 0) {
            _leftMenuItems[0].children.push({
              id: 'reportingDashboard',
              title: 'Reporting Dashboard',
              type: 'item',
              url: '/reportingDashboard',
              icon: DashboardIcon,
              breadcrumbs: false,
            });
          }
            _leftMenuItems[0].children.push({
              id: 'DOALogs',
              title: 'DOA Logs',
              type: 'item',
              url: '/doaLogs',
              icon: DescriptionIcon,
              breadcrumbs: false,
            });
          _leftMenuItems[0].children.push({
            id: 'schedule',
            title: 'Schedule',
            type: 'item',
            url: '/schedule',
            icon: CalendarMonthIcon,
            breadcrumbs: false,
          });
          _leftMenuItems[0].children.push({
            id: 'booking',
            title: 'Booking Slot',
            type: 'item',
            url: '/booking',
            icon: InsertInvitationIcon,
            breadcrumbs: false,
          });
          _leftMenuItems[0].children.push({
            id: 'profile',
            title: 'Profile',
            type: 'item',
            url: '/accountDetails',
            icon: AccountCircleIcon,
            breadcrumbs: false,
          });
          return _leftMenuItems;
        }
        break;
      case 'sponsor':
        if (!subjectLoggedIn) {
          const _leftMenuItems = _.cloneDeep(menuItem.items);
          if (userDetails && userDetails?.dashboardPreferences?.length >= 0) {
            _leftMenuItems[0].children.push({
              id: 'reportingDashboard',
              title: 'Reporting Dashboard',
              type: 'item',
              url: '/reportingDashboard',
              icon: DashboardIcon,
              breadcrumbs: false,
            });
          }
          _leftMenuItems[0].children.push({
            id: 'profile',
            title: 'Profile',
            type: 'item',
            url: '/accountDetails',
            icon: AccountCircleIcon,
            breadcrumbs: false,
          });  
          _leftMenuItems[0].children.push({
            id: 'eTMFsDocument',
            title: 'eTMFs / eISF',
            type: 'item',
            url: '/eTMFs-eISF',
            icon: DescriptionIcon,
            breadcrumbs: false,
          });  
          return _leftMenuItems;
        }
        break;
      case "system_admin":
        if (!subjectLoggedIn) {
          const _leftMenuItems = _.cloneDeep(menuItem.items);
          _leftMenuItems[0].children.push({
            id: 'sponsorDetails',
            title: 'Sponsor',
            type: 'item',
            url: '/sponsor',
            icon: GroupsIcon,
            breadcrumbs: false,
          });
          _leftMenuItems[0].children.push({
            id: 'manageConfiguration',
            title: 'Configuration',
            type: 'item',
            url: '/configuration',
            icon: SettingsIcon,
            breadcrumbs: false,
          });
          _leftMenuItems[0].children.push({
            id: 'finance',
            title: 'Finance',
            type: 'item',
            url: '/finance',
            icon: AccountBalanceOutlinedIcon,
            breadcrumbs: false,
          });
          // _leftMenuItems[0].children.push({
          //   id: 'pfasTrak',
          //   title: 'PFASTrak',
          //   type: 'item',
          //   url: '/pfasTrak',
          //   icon: SpaIcon,
          //   breadcrumbs: false,
          // });
          return _leftMenuItems;
        }
        break;
      default:
        return menuItem.items;
    }
  }, [subjectDashboardInfo,user, userDetails, enrollmentFormData]);

  const navGroups = crfStepStatus?.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} handleDrawerToggle={handleDrawerToggle} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return (
    <Box sx={{ pt: 2, height: "100vh", display: 'flex', alignItems: "space-between", flexDirection: "column" }} >
      <Box>
        {!isSmallScreen && <Box sx={{ display: "flex", justifyContent: "end", paddingRight: 1 }}>
          <IconButton
            disableRipple
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            color="secondary"
            sx={{
              color: "text.primary",
              bgcolor: `${theme.palette.grey[100]}`,
              ml: { xs: 0, lg: -2 },
            }}
          >
            {open ? <NavigateBeforeIcon /> : <NavigateNextIcon />}
          </IconButton>
        </Box>}
        {navGroups}</Box>
      {open && <Box sx={{ marginTop: 'auto', display: 'flex', justifyContent: 'center', pb: 3 }}><Logo /> </Box>}

    </Box>
  );
};

export default Navigation;
